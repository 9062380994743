.win-square{
    border: 2px solid rgb(0, 255, 4);
    box-shadow: 6px 6px 0px 0px rgba(51, 255, 0, 0.781);
    text-shadow: 3px 3px 0px rgba(51, 255, 0, 0.781);
}
.square{
    width: 100px;
    height: 100px;
    font-size: 60px;
    text-align: center;
    border-radius: 25px;
    margin: 5px;
}
.square-data{
    margin-top: 10px;
    font-weight: 1000;
}
.dark-square{
    border: 1px solid rgb(255, 255, 255);
    box-shadow: 6px 6px 0px 0px rgb(255 255 255 / 79%);
    text-shadow: 3px 3px 0px rgba(255, 255, 255, 0.429);
}
.light-square{
    border: 2px solid rgb(0, 0, 0);
    box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.781);
    text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.429);
}
.dark-square-data{
    color:white;
}
.light-square-data{
    color:black;
}