body{
    background-color: black;
}
.start-page-container{
    padding:25px;
}
.start-page{
    color: white;
    font-size: 10vw;
    font-weight: 500;
    box-shadow: 11px 11px 0px 0px rgba(255 ,255, 255, 0.79);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 30px;
    text-shadow:1.5px 1.5px 0px rgba(255, 255, 255, 0.79);
}
.heading-container{
    box-shadow: none;
    border: none;
    height: 20vh;
    margin-top: 5vh;
}
.start-page-mode-container{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.mode{
    width: 45vw;
    min-width:300px;
    height: 65px;
    font-size: 30px;
    margin: 20px;
}
.clicked-btn1{
    transition: 0.6s;
    background-color: rgba(51, 255, 0, 0.766) ;
    border: 3px solid rgba(255, 255, 255, 0.851);
    box-shadow: 8px 8px 0px 0px rgba(51, 255, 0, 0.733),9px 9px 0px 0.5px rgba(255,255,255);
}
.clicked-btn2{
    transition: 0.6s;
    background-color: rgba(255, 0, 72, 0.862);
    border: 3px solid rgba(255, 255, 255, 0.851);
    box-shadow: 8px 8px 0px 0px rgba(255, 0, 93, 0.877),9px 9px 0px 0.5px rgba(255,255,255);
}
.start-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.start-btn{
    margin: 30px;
    height: 100px;
    width: 100px;
    font-size: 50px;
    border-radius: 50px;
}
.start-btn:hover{
    transition: 0.4s;
    background-color: white;
    box-shadow: 6px 6px 0px 0px rgba(122, 0, 253, 0.838);
}