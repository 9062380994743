.game-container{
    margin-top: 60px;
}
.game-winner{
    font-size: 50px;
    font-weight: 1000;
    margin-bottom: 20px;
    height: 60px;
    border-radius: 50px;
    box-shadow: 2px 3px 15px 2px rgba(0, 0, 0, 0.399);
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 330px;
}
.game{
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    margin-top: 30px;
}
.game-settings{
    padding-top: 50px;
    padding-left: 250px;
}
.hide{
    display: none;
}
.light{
    background-color: rgba(0, 0, 0, 0.86);
    box-shadow: 11px 11px 0px 0px rgba(0, 0, 0, 0.69);
    color: white;
}
.dark{
    background-color: rgba(255, 255, 255);
    box-shadow: 11px 11px 0px 0px rgba(255 ,255, 255, 0.79);
    color: rgb(0, 0, 0);
}
.game-over-outer-container{
    filter: alpha(opacity=20);
    background-color:rgba(0, 0, 0, 0.77); 
    width:100%; 
    height:100%; 
    z-index:10;
    top:0; 
    left:0; 
    position:fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}
.game-over-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.game-over-action{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 50px;
    margin: 20px;
    width: 100px;
    height: 100px;
    box-shadow: 8px 8px 0px 0px rgba(255 ,255, 255, 0.79);
    background-color: white;
}
.game-over-action:hover{
    box-shadow: 6px 6px 0px 0px rgba(255 ,255, 255, 0.79);
    background-color: rgb(235, 223, 223);
}